// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
import './stylesheets/application'

import 'channels';
import 'jquery';
import 'jquery-ui';
import 'trix';
import '@rails/actiontext'
import 'bootstrap';
import '@fortawesome/fontawesome-free/js/all'
import 'admin-lte';

require('@rails/ujs').start();
require('turbolinks').start();
require('@rails/activestorage').start();

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

const CHOOSE_FILE = '選択してください';

const customFile = () => {
  $('.custom-file').each((i, element) => {
    let file = $(element);
    let input = file.find('.custom-file-input');
    let label = file.find('.custom-file-label');

    label.text(CHOOSE_FILE);
    input.change(() => {
      let text = input.val();
      if (text === '' || text == null) {
        label.text(CHOOSE_FILE);
      } else {
        label.text(text);
      }
    });
  });
};


const customString = () => {
  $('.custom-string').each((i, element) => {
    let control = $(element);
    let container = control.find('.custom-string-container');
    let blank = container.children().last().remove();
    let button = control.find('button');

    let handleKeyEvents = (event) => {
      if (event.key === 'Backspace' || event.key === 'Delete') {
        let element = $(event.target);
        if (element.val() === '') {
          element.closest('.custom-string-item').prev().find('input[type=text]').focus();
          element.closest('.custom-string-item').remove();
          return false;
        }
      } else if (event.key === 'Tab' && !event.shiftKey) {
        let element = $(event.target);
        if (element.closest('.custom-string-item').next().length === 0) {
          appendItem();
          element.closest('.custom-string-item').next().find('input[type=text]').focus();
          return false;
        }
      }
    };

    let appendItem = () => {
      let newItem = blank.clone();
      container.append(newItem);
      newItem.find('input[type=text]').keydown(handleKeyEvents);
      return false;
    };

    container.find('input[type=text]').keydown(handleKeyEvents);
    container.sortable({containment: control});

    button.click(appendItem);
  });
};

const answer = () => {
  $('.answer').each((index, element) => {
    let self = $(element);
    let panes = self.find('.tab-content .tab-pane');
    let links = self.find('.nav-pills a.nav-link');

    panes.find('a.btn').click((e) => {
      let target = $(e.target);
      $(target.attr('href')).click();
      return false;
    });

    panes.find('button.btn').click((e) => {
      let pane = $(e.target).closest('.tab-pane');
      let main = $('.main-header').outerHeight();
      let content = $('.content-header').outerHeight();
      $(window).scrollTop(pane.find('.evaluation-invalid').position().top + main + content);
      return false;
    });

    let updateLink = () => {
      for (let i = 0; i < panes.length; ++i) {
        let pane = $(panes[i]);
        let link = $(links[i + 1]);
        let controls = pane.find('input[type=radio],input[type=text],textarea');

        let names = Array.from(new Set(controls.map((i, element) => {
          return $(element).attr('name');
        })));

        let completed = true;
        for (let j = 0; j < names.length; ++j) {
          let name = names[j].replace(/\[/g, '\\[').replace(/]/g, '\\]');
          let radios = $('input[type=radio][name=' + name + ']')
          if (radios.length !== 0) {
            if ($('input[type=radio][name=' + name + ']:checked').length === 0) {
              completed = false;
              radios.closest('.evaluation-block').addClass('evaluation-invalid');
              break;
            } else {
              radios.closest('.evaluation-block').removeClass('evaluation-invalid');
            }
          } else {
            let text = $('input.required[type=text][name=' + name + '],textarea.required[name=' + name + ']');
            if (text.length !== 0) {
              if (text.val() === '') {
                completed = false;
                text.closest('.evaluation-block').addClass('evaluation-invalid');
                break;
              } else {
                text.closest('.evaluation-block').removeClass('evaluation-invalid');
              }
            }
          }
        }

        if (completed) {
          pane.find('a.btn,input[name=commit][type=submit]').css('display', 'inline-block').removeClass('disabled');
          pane.find('button.btn').css('display', 'none').addClass('disabled');
          $(link).removeClass('disabled');
        } else {
          pane.find('a.btn,input[name=commit][type=submit]').css('display', 'none').addClass('disabled');
          pane.find('button.btn').css('display', 'inline-block').removeClass('disabled');
          $(link).addClass('disabled');
        }
      }
    };

    panes.find('input[type=radio],input[type=text],textarea').change(updateLink);
    panes.find('input[type=text],textarea').on('input', updateLink);

    updateLink();
  });
};

const recipientSelect = () => {
  let filterType = $('#broadcast_mail_recipient_filter_type');
  let select = $('#recipient-select');

  if (0 < filterType.length) {
    let updateRecipientSelect = () => {
      if (filterType.val() === 'custom') {
        select.removeClass('d-none');
      } else {
        select.addClass('d-none');
      }
    };

    updateRecipientSelect();
    filterType.change(updateRecipientSelect);
  }
};

$(document).on(
  'turbolinks:load',
  () => {
    customFile();
    customString();
    answer();
    recipientSelect();

    $('.toast').toast({autohide: false}).toast('show');
    $('.sidebar-toggle-btn').PushMenu();
    $('body').Layout('fixLayoutHeight');
  }
);
